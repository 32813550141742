import React from "react"
import NavItems from "./navItems"

const DesktopMenu = () => {
  return (
    <>
      <NavItems />
    </>
  )
}

export default DesktopMenu
